import {companiesBloodhound, filterTypeahead, filterTypeaheadNoCache, screensTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class ComplianceClientConditionsEdit {

  constructor() {}

  render() {
    let accountUrl = $('body').attr('data-account-url');

    // Search 
    filterTypeahead('#search_metrics', 'metrics', '/metrics/search?', 550, 'cause');

    $('#search_metrics').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).closest('.form-group').find('.hidden-field').val(suggestion.value);
    });


  }
}