// Import JS used on multiple pages
import CardList from 'src/javascripts/components/CardList';
import Collapsible from 'src/javascripts/components/utilities/Collapsible';
import CollapsibleCardLists from 'src/javascripts/components/utilities/CollapsibleCardLists';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import FocusSearchForm from 'src/javascripts/components/forms/FocusSearchForm';
import Guides from 'src/javascripts/components/Guides';
import ImageReposition from 'src/javascripts/components/utilities/ImageReposition';
import NavbarNotifications from 'src/javascripts/components/notifications/NavbarNotifications';
import NavPublic from 'src/javascripts/components/nav/NavPublic';
import MasterSearchTypeahead from 'src/javascripts/components/typeaheads/MasterSearchTypeahead';
import MasterSearchClientsTypeahead from 'src/javascripts/components/typeaheads/MasterSearchClientsTypeahead';
import MasterSearchTypeaheadVantage from 'src/javascripts/components/typeaheads/MasterSearchTypeaheadVantage';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import Sidebar from 'src/javascripts/components/nav/Sidebar';
import SocialShare from 'src/javascripts/components/SocialShare';
import SummernoteDefault from 'src/javascripts/components/SummernoteDefault';
import ToastFlash from 'src/javascripts/components/alerts/ToastFlash';
import ToggleUserEnv from 'src/javascripts/components/nav/ToggleUserEnv';
import TooltipInit from 'src/javascripts/components/utilities/TooltipInit';

// Accounts pages
import AccountsShow from 'src/javascripts/pages/accounts/AccountsShow';
import AccountsImpactCalculatorsNew from 'src/javascripts/pages/accounts/impact_calculators/AccountsImpactCalculatorsNew';
import AccountsImpactCalculatorsEdit from 'src/javascripts/pages/accounts/impact_calculators/AccountsImpactCalculatorsEdit';
import AccountsImpactCalculatorsShow from 'src/javascripts/pages/accounts/impact_calculators/AccountsImpactCalculatorsShow';
import AccountsImpactCalculatorsPublic from 'src/javascripts/pages/accounts/impact_calculators/AccountsImpactCalculatorsPublic';
import AccountsManagedFundsEdit from 'src/javascripts/pages/accounts/managed_funds/AccountsManagedFundsEdit';

// Admin pages
import Admin from 'src/javascripts/pages/Admin';

// API
import ApiDocsIndex from 'src/javascripts/pages/api/docs/ApiDocsIndex';
import ApiKeysIndex from 'src/javascripts/pages/api/keys/ApiKeysIndex';
import ApiLogsIndex from 'src/javascripts/pages/api/logs/ApiLogsIndex';
import ApiWebhooksSubscriptionsEdit from 'src/javascripts/pages/api/webhooks/ApiWebhooksSubscriptionsEdit';

// Assessment pages
import AssessmentsIndex from 'src/javascripts/pages/assessments/AssessmentsIndex';
import AssessmentsPreferences from 'src/javascripts/pages/assessments/AssessmentsPreferences';
import AssessmentsEditCategories from 'src/javascripts/pages/assessments/AssessmentsEditCategories';
import AssessmentsRankCategories from 'src/javascripts/pages/assessments/AssessmentsRankCategories';
import AssessmentsEditCategory from 'src/javascripts/pages/assessments/AssessmentsEditCategory';
import AssessmentsQuestions from 'src/javascripts/pages/assessments/AssessmentsQuestions';
import AssessmentsResults from 'src/javascripts/pages/assessments/AssessmentsResults';
import AssessmentsSave from 'src/javascripts/pages/assessments/AssessmentsSave';
import AssessmentsContactInfo from 'src/javascripts/pages/assessments/AssessmentsContactInfo';
import AssessmentsScreens from 'src/javascripts/pages/assessments/AssessmentsScreens';
import AssessmentsAdvocacy from 'src/javascripts/pages/assessments/AssessmentsAdvocacy';
import AssessmentsFaithBased from 'src/javascripts/pages/assessments/AssessmentsFaithBased';

// Auth pages
import Auth from 'src/javascripts/pages/Auth';

// Clients
import ClientsAssessmentsShow from 'src/javascripts/pages/clients/ClientsAssessmentsShow';
import ClientsAssessmentsSetCauses from 'src/javascripts/pages/clients/ClientsAssessmentsSetCauses';
import ClientsEmailsNew from 'src/javascripts/pages/clients/ClientsEmailsNew';
import ClientsIndex from 'src/javascripts/pages/clients/ClientsIndex';
import ClientsShow from 'src/javascripts/pages/clients/ClientsShow';
import ClientsImportsNew from 'src/javascripts/pages/clients/ClientsImportsNew';
import ClientsEdit from 'src/javascripts/pages/clients/ClientsEdit';
import ClientsNew from 'src/javascripts/pages/clients/ClientsNew';
import ClientsHouseholdsEdit from 'src/javascripts/pages/clients/households/ClientsHouseholdsEdit';

// Compliance/approval pages
import ComplianceIndex from 'src/javascripts/pages/compliance/ComplianceIndex';
import ComplianceApprovalsEdit from 'src/javascripts/pages/compliance/approvals/ComplianceApprovalsEdit';
import ComplianceApprovalTestsEdit from 'src/javascripts/pages/compliance/approval_tests/ComplianceApprovalTestsEdit';
import ComplianceApprovalTestRunsEdit from 'src/javascripts/pages/compliance/approval_test_runs/ComplianceApprovalTestRunsEdit';
import ComplianceApprovalRulesEdit from 'src/javascripts/pages/compliance/approval_rules/ComplianceApprovalRulesEdit';
import ComplianceApprovalRulesNew from 'src/javascripts/pages/compliance/approval_rules/ComplianceApprovalRulesNew';
import ComplianceClientConditionsIndex from 'src/javascripts/pages/compliance/client_conditions/ComplianceClientConditionsIndex';
import ComplianceClientConditionsEdit from 'src/javascripts/pages/compliance/client_conditions/ComplianceClientConditionsEdit';
import ComplianceClientConditionsShow from 'src/javascripts/pages/compliance/client_conditions/ComplianceClientConditionsShow';
import ComplianceClientConditionsSideLettersEdit from 'src/javascripts/pages/compliance/client_conditions/side_letters/ComplianceClientConditionsSideLettersEdit';

// Firm pages
import Firms from 'src/javascripts/pages/Firms';

// Help center pages
import Help from 'src/javascripts/pages/Help';

// Marketplace
import MarketplaceProductsPreview from 'src/javascripts/pages/marketplace/products/MarketplaceProductsPreview';
import MarketplaceProductsShow from 'src/javascripts/pages/marketplace/products/MarketplaceProductsShow';
import MarketplaceStripeInvoicesBilling from 'src/javascripts/pages/marketplace/stripe/invoices/MarketplaceStripeInvoicesBilling';
import MarketplaceStripeSubscriptionsEdit from 'src/javascripts/pages/marketplace/stripe/subscriptions/MarketplaceStripeSubscriptionsEdit';
import MarketplaceStripeSubscriptionsNew from 'src/javascripts/pages/marketplace/stripe/subscriptions/MarketplaceStripeSubscriptionsNew';

// Public pages
import Public from 'src/javascripts/pages/Public';
import PublicLanding from 'src/javascripts/pages/public/PublicLanding';
import PublicCompaniesIndex from 'src/javascripts/pages/public/companies/PublicCompaniesIndex';
import PublicCompaniesShow from 'src/javascripts/pages/public/companies/PublicCompaniesShow';
import PublicFundsIndex from 'src/javascripts/pages/public/funds/PublicFundsIndex';
import PublicFundsShow from 'src/javascripts/pages/public/funds/PublicFundsShow';

// Notifications
import NotificationsIndex from 'src/javascripts/pages/notifications/NotificationsIndex';
import NotificationsSettingsIndex from 'src/javascripts/pages/notifications/settings/NotificationsSettingsIndex';

// Portfolios
import PortfoliosNew from 'src/javascripts/pages/portfolios/PortfoliosNew';
import PortfoliosEdit from 'src/javascripts/pages/portfolios/PortfoliosEdit';
import PortfoliosClimateRiskIndex from 'src/javascripts/pages/portfolios/climate_risk/PortfoliosClimateRiskIndex';
import PortfoliosControversiesIndex from 'src/javascripts/pages/portfolios/controversies/PortfoliosControversiesIndex';
import PortfoliosDashboardsOutsideInIndex from 'src/javascripts/pages/portfolios/dashboards/PortfoliosDashboardsOutsideInIndex';
import PortfoliosDashboardsQuestionnairesIndex from 'src/javascripts/pages/portfolios/dashboards/PortfoliosDashboardsQuestionnairesIndex';
import PortfoliosDashboardsImpactIndex from 'src/javascripts/pages/portfolios/dashboards/PortfoliosDashboardsImpactIndex';
import PortfoliosDashboardsRiskIndex from 'src/javascripts/pages/portfolios/dashboards/PortfoliosDashboardsRiskIndex';
import PortfoliosAssets from 'src/javascripts/pages/portfolios/PortfoliosAssets';
import PortfoliosMetricsShow from 'src/javascripts/pages/portfolios/metrics/PortfoliosMetricsShow';
import PortfoliosMetricScoresCarbonFootprintDetails from 'src/javascripts/pages/portfolios/metric_scores/PortfoliosMetricScoresCarbonFootprintDetails';
import PortfoliosModelsNew from 'src/javascripts/pages/portfolios/models/PortfoliosModelsNew';
import PortfoliosModelsEdit from 'src/javascripts/pages/portfolios/models/PortfoliosModelsEdit';
import PortfoliosModelsImportsNew from 'src/javascripts/pages/portfolios/models/PortfoliosModelsImportsNew';
import PortfoliosModelsImportsMap from 'src/javascripts/pages/portfolios/models/PortfoliosModelsImportsMap';
import PortfoliosModelsBulkImportsNew from 'src/javascripts/pages/portfolios/models/PortfoliosModelsBulkImportsNew';
import PortfoliosModelsBulkImportsMap from 'src/javascripts/pages/portfolios/models/PortfoliosModelsBulkImportsMap';
import PortfoliosScreensIndex from 'src/javascripts/pages/portfolios/screens/PortfoliosScreensIndex';
import RecommendationsIndex from 'src/javascripts/pages/recommendations/RecommendationsIndex';
import RecommendationsEdit from 'src/javascripts/pages/recommendations/RecommendationsEdit';
import RecommendationsNew from 'src/javascripts/pages/recommendations/RecommendationsNew';
import RecommendationsSummariesNew from 'src/javascripts/pages/recommendations/summaries/RecommendationsSummariesNew';
import RecommendationsSummariesEdit from 'src/javascripts/pages/recommendations/summaries/RecommendationsSummariesEdit';
import RecommendationsSummaryModulesEdit from 'src/javascripts/pages/recommendations/summary_modules/RecommendationsSummaryModulesEdit';
import RecommendationsSummaryModulesNew from 'src/javascripts/pages/recommendations/summary_modules/RecommendationsSummaryModulesNew';
import RecommendationsSummariesNewImport from 'src/javascripts/pages/recommendations/summaries/RecommendationsSummariesNewImport';
import RecommendationsNewImport from 'src/javascripts/pages/recommendations/RecommendationsNewImport';
import RecommendationsSummaryModulesNewImport from 'src/javascripts/pages/recommendations/summary_modules/RecommendationsSummaryModulesNewImport';
import RecommendationsActionPlansEdit from 'src/javascripts/pages/recommendations/action_plans/RecommendationsActionPlansEdit';
import RecommendationsActionPlansNew from 'src/javascripts/pages/recommendations/action_plans/RecommendationsActionPlansNew';
import RecommendationsActionPlansNewImport from 'src/javascripts/pages/recommendations/action_plans/RecommendationsActionPlansNewImport';
import ResearchLocationsShow from 'src/javascripts/pages/research/locations/ResearchLocationsShow';
import PortfoliosCarbonFootprintsIndex from 'src/javascripts/pages/portfolios/carbon_footprints/PortfoliosCarbonFootprintsIndex';

// Questionnaires
import QuestionnairesCollectionDashboardsIndex from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionDashboardsIndex';
import QuestionnairesCollectionDashboardsAssessmentSummaries from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionDashboardsAssessmentSummaries';
import QuestionnairesCollectionDocumentsIndex from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionDocumentsIndex';
import QuestionnairesCollectionImportsNew from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionImportsNew';
import QuestionnairesCollectionModulesIndex from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionModulesIndex';
import QuestionnairesCollectionModulesShow from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionModulesShow';
import QuestionnairesCollectionModulesGrid from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionModulesGrid';
import QuestionnairesCollectionResponsesDashboard from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionResponsesDashboard';
import QuestionnairesCollectionResponsesNewAccount from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionResponsesNewAccount';
import QuestionnairesCollectionResponsesWelcome from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionResponsesWelcome';
import QuestionnairesCollectionApprovalRequestsIndex from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionApprovalRequestsIndex';
import QuestionnairesCollectionApprovalActionsNew from 'src/javascripts/pages/questionnaires/collection/QuestionnairesCollectionApprovalActionsNew';
import QuestionnairesEmailsNew from 'src/javascripts/pages/questionnaires/emails/QuestionnairesEmailsNew';
import QuestionnairesIncludedModulesNew from 'src/javascripts/pages/questionnaires/included/modules/QuestionnairesIncludedModulesNew';
import QuestionnairesIncludedModulesEdit from 'src/javascripts/pages/questionnaires/included/modules/QuestionnairesIncludedModulesEdit';
import QuestionnairesIncludedQuestionsEdit from 'src/javascripts/pages/questionnaires/included/questions/QuestionnairesIncludedQuestionsEdit';
import QuestionnairesIncludedQuestionsNew from 'src/javascripts/pages/questionnaires/included/questions/QuestionnairesIncludedQuestionsNew';
import QuestionnairesIncludedSectionsEdit from 'src/javascripts/pages/questionnaires/included/sections/QuestionnairesIncludedSectionsEdit';
import QuestionnairesIncludedSectionsNew from 'src/javascripts/pages/questionnaires/included/sections/QuestionnairesIncludedSectionsNew';
import QuestionnairesIncludedTablesEdit from 'src/javascripts/pages/questionnaires/included/tables/QuestionnairesIncludedTablesEdit';
import QuestionnairesIncludedTablesNew from 'src/javascripts/pages/questionnaires/included/tables/QuestionnairesIncludedTablesNew';
import QuestionnairesIncludedTextsEdit from 'src/javascripts/pages/questionnaires/included/texts/QuestionnairesIncludedTextsEdit';
import QuestionnairesIncludedTextsNew from 'src/javascripts/pages/questionnaires/included/texts/QuestionnairesIncludedTextsNew';
import QuestionnairesQuestionsEdit from 'src/javascripts/pages/questionnaires/questions/QuestionnairesQuestionsEdit';
import QuestionnairesQuestionsNew from 'src/javascripts/pages/questionnaires/questions/QuestionnairesQuestionsNew';
import QuestionnairesResponsesIndex from 'src/javascripts/pages/questionnaires/responses/QuestionnairesResponsesIndex';
import QuestionnairesResponsesEdit from 'src/javascripts/pages/questionnaires/responses/QuestionnairesResponsesEdit';
import QuestionnairesResponsesNew from 'src/javascripts/pages/questionnaires/responses/QuestionnairesResponsesNew';
import QuestionnairesResponsesShow from 'src/javascripts/pages/questionnaires/responses/QuestionnairesResponsesShow';
import QuestionnairesModulesNewImport from 'src/javascripts/pages/questionnaires/modules/QuestionnairesModulesNewImport';
import QuestionnairesSectionsNewImport from 'src/javascripts/pages/questionnaires/sections/QuestionnairesSectionsNewImport';
import QuestionnairesQuestionsNewImport from 'src/javascripts/pages/questionnaires/questions/QuestionnairesQuestionsNewImport';
import QuestionnairesTablesEdit from 'src/javascripts/pages/questionnaires/tables/QuestionnairesTablesEdit';
import QuestionnairesIndex from 'src/javascripts/pages/questionnaires/QuestionnairesIndex';
import QuestionnairesNew from 'src/javascripts/pages/questionnaires/QuestionnairesNew';
import QuestionnairesEdit from 'src/javascripts/pages/questionnaires/QuestionnairesEdit';
import QuestionnairesResponsesAnswersIndex from 'src/javascripts/pages/questionnaires/responses/answers/QuestionnairesResponsesAnswersIndex';
import QuestionnairesResponsesApprovalRequestsIndex from 'src/javascripts/pages/questionnaires/responses/approval_requests/QuestionnairesResponsesApprovalRequestsIndex';
import QuestionnairesResponsesApprovalRequestsNew from 'src/javascripts/pages/questionnaires/responses/approval_requests/QuestionnairesResponsesApprovalRequestsNew';
import QuestionnairesResponsesApprovalActionsNew from 'src/javascripts/pages/questionnaires/responses/approval_actions/QuestionnairesResponsesApprovalActionsNew';

// Reports pages
import Reports from 'src/javascripts/pages/Reports';
import ReportsEdit from 'src/javascripts/pages/reports/ReportsEdit';
import ReportsNew from 'src/javascripts/pages/reports/ReportsNew';
import ReportsIndex from 'src/javascripts/pages/reports/ReportsIndex';
import ReportsSchedulesEdit from 'src/javascripts/pages/reports/schedules/ReportsSchedulesEdit';

// Research pages
import ResearchCompaniesIndex from 'src/javascripts/pages/research/companies/ResearchCompaniesIndex';
import ResearchCompaniesShow from 'src/javascripts/pages/research/companies/ResearchCompaniesShow';
import ResearchCompaniesRealWorldMetricsShow from 'src/javascripts/pages/research/companies/ResearchCompaniesRealWorldMetricsShow';
import ResearchFundsIndex from 'src/javascripts/pages/research/funds/ResearchFundsIndex';
import ResearchFundsShow from 'src/javascripts/pages/research/funds/ResearchFundsShow';
import ResearchFundsScreensIndex from 'src/javascripts/pages/research/funds/screens/ResearchFundsScreensIndex';
import ResearchFundsTemperatureIndex from 'src/javascripts/pages/research/funds/ResearchFundsTemperatureIndex';
import ResearchFundsRealWorldMetricsShow from 'src/javascripts/pages/research/funds/ResearchFundsRealWorldMetricsShow';
import ResearchCausesShow from 'src/javascripts/pages/research/causes/ResearchCausesShow';
import ResearchCountriesShow from 'src/javascripts/pages/research/countries/ResearchCountriesShow';
import ResearchControversiesIndex from 'src/javascripts/pages/research/controversies/ResearchControversiesIndex';
import ResearchScreensIndex from 'src/javascripts/pages/research/screens/ResearchScreensIndex';
import ResearchFavoritesIndex from 'src/javascripts/pages/research/favorites/ResearchFavoritesIndex';
import ResearchLocationsIndex from 'src/javascripts/pages/research/locations/ResearchLocationsIndex';

// Settings
import SettingsAccountsEdit from 'src/javascripts/pages/settings/accounts/SettingsAccountsEdit';
import SettingsAccountsMethodologiesEdit from 'src/javascripts/pages/settings/accounts/methodologies/SettingsAccountsMethodologiesEdit';
import SettingsAccountsCustomMetricsImportsNew from 'src/javascripts/pages/settings/accounts/custom_metrics/SettingsAccountsCustomMetricsImportsNew';
import SettingsAccountsCustomMetricsIndex from 'src/javascripts/pages/settings/accounts/custom_metrics/SettingsAccountsCustomMetricsIndex';
import SettingsAccountsMethodologiesCompaniesNew from 'src/javascripts/pages/settings/accounts/methodologies/companies/SettingsAccountsMethodologiesCompaniesNew';
import SettingsAccountsMethodologiesImportsNew from 'src/javascripts/pages/settings/accounts/methodologies/SettingsAccountsMethodologiesImportsNew';
import SettingsAccountsMembershipsIndex from 'src/javascripts/pages/settings/accounts/memberships/SettingsAccountsMembershipsIndex';
import SettingsAccountsRestrictedAssetsIndex from 'src/javascripts/pages/settings/accounts/restricted_assets/SettingsAccountsRestrictedAssetsIndex';
import SettingsAccountsRestrictedAssetsEdit from 'src/javascripts/pages/settings/accounts/restricted_assets/SettingsAccountsRestrictedAssetsEdit';
import SettingsAccountsRolesNew from 'src/javascripts/pages/settings/accounts/roles/SettingsAccountsRolesNew';
import SettingsAccountsRolesEdit from 'src/javascripts/pages/settings/accounts/roles/SettingsAccountsRolesEdit';
import SettingsAccountsImagesIndex from 'src/javascripts/pages/settings/accounts/images/SettingsAccountsImagesIndex';
import SettingsUsersSecurity from 'src/javascripts/pages/settings/users/SettingsUsersSecurity';
import SettingsAccountsIntegrationsIndex from 'src/javascripts/pages/settings/accounts/integrations/SettingsAccountsIntegrationsIndex';
import SettingsAccountsMethodologiesCausesNew from 'src/javascripts/pages/settings/accounts/methodologies/causes/SettingsAccountsMethodologiesCausesNew';
import SettingsAccountsMethodologiesCausesEdit from 'src/javascripts/pages/settings/accounts/methodologies/causes/SettingsAccountsMethodologiesCausesEdit';
import SettingsAccountsMethodologiesFormulasNew from 'src/javascripts/pages/settings/accounts/methodologies/formulas/SettingsAccountsMethodologiesFormulasNew';
import SettingsAccountsMethodologiesFormulasEdit from 'src/javascripts/pages/settings/accounts/methodologies/formulas/SettingsAccountsMethodologiesFormulasEdit';

// Create a map where keys share same names as classes
const pageClasses = {
	AccountsImpactCalculatorsPublic, AccountsImpactCalculatorsNew, AccountsImpactCalculatorsShow, 
	AccountsManagedFundsEdit, AccountsShow, AccountsImpactCalculatorsEdit, 
	ApiDocsIndex, ApiKeysIndex, ApiLogsIndex, ApiWebhooksSubscriptionsEdit, 
	AssessmentsContactInfo, AssessmentsEditCategories, AssessmentsEditCategory, 
	AssessmentsIndex, AssessmentsPreferences, AssessmentsQuestions, 
	AssessmentsRankCategories, AssessmentsResults, AssessmentsSave,
	AssessmentsScreens, AssessmentsAdvocacy,
	ClientsAssessmentsSetCauses, ClientsAssessmentsShow, ClientsEdit, ClientsNew, 
	ClientsShow, ClientsIndex, ClientsEmailsNew, ClientsImportsNew, ClientsHouseholdsEdit,
  ComplianceApprovalsEdit, ComplianceIndex, ComplianceApprovalTestsEdit,
  ComplianceApprovalTestRunsEdit, ComplianceApprovalRulesEdit, 
  ComplianceApprovalRulesNew, ComplianceClientConditionsEdit, ComplianceClientConditionsShow, 
  ComplianceClientConditionsSideLettersEdit, ComplianceClientConditionsIndex, 
  MarketplaceProductsShow, MarketplaceProductsPreview, MarketplaceStripeInvoicesBilling, 
  MarketplaceStripeSubscriptionsEdit, MarketplaceStripeSubscriptionsNew, 
  NotificationsIndex, NotificationsSettingsIndex, 
  PortfoliosDashboardsRiskIndex, PortfoliosAssets, PortfoliosDashboardsImpactIndex, 
  PortfoliosScreensIndex, PortfoliosModelsImportsNew,
  PortfoliosEdit, PortfoliosMetricsShow, PortfoliosModelsImportsMap, PortfoliosNew, 
  PortfoliosModelsEdit, PortfoliosMetricScoresCarbonFootprintDetails,
  PortfoliosModelsBulkImportsNew, PortfoliosModelsBulkImportsMap, PortfoliosModelsNew, 
  PortfoliosDashboardsOutsideInIndex, PortfoliosDashboardsQuestionnairesIndex, 
  PortfoliosClimateRiskIndex, PortfoliosCarbonFootprintsIndex, PortfoliosControversiesIndex, 
  PublicLanding, PublicCompaniesIndex, PublicCompaniesShow, PublicFundsIndex, 
  PublicFundsShow, 
  QuestionnairesIndex, QuestionnairesEdit, QuestionnairesQuestionsEdit, 
  QuestionnairesQuestionsNew, QuestionnairesResponsesEdit,
  QuestionnairesResponsesNew, QuestionnairesCollectionModulesShow,
  QuestionnairesResponsesShow, QuestionnairesCollectionResponsesWelcome,
  QuestionnairesCollectionResponsesNewAccount,
  QuestionnairesCollectionImportsNew, QuestionnairesCollectionDocumentsIndex,
  QuestionnairesResponsesAnswersIndex,
  QuestionnairesCollectionModulesIndex, QuestionnairesTablesEdit,
  QuestionnairesEmailsNew, QuestionnairesCollectionDashboardsIndex,
  QuestionnairesIncludedModulesEdit,
  QuestionnairesIncludedQuestionsEdit, QuestionnairesIncludedQuestionsNew, 
  QuestionnairesIncludedSectionsEdit, QuestionnairesIncludedSectionsNew, 
  QuestionnairesIncludedTextsEdit, QuestionnairesIncludedTextsNew, QuestionnairesIncludedModulesNew,
  QuestionnairesModulesNewImport, QuestionnairesSectionsNewImport, QuestionnairesQuestionsNewImport,
  QuestionnairesNew, QuestionnairesCollectionDashboardsAssessmentSummaries, 
  QuestionnairesResponsesIndex, QuestionnairesIncludedTablesNew, 
  QuestionnairesIncludedTablesEdit, QuestionnairesCollectionApprovalRequestsIndex, 
  QuestionnairesCollectionModulesGrid, QuestionnairesResponsesApprovalRequestsIndex,
  QuestionnairesResponsesApprovalRequestsNew, QuestionnairesResponsesApprovalActionsNew, 
  QuestionnairesCollectionApprovalActionsNew, QuestionnairesCollectionResponsesDashboard, 
	RecommendationsSummariesEdit, RecommendationsSummariesNew, RecommendationsSummaryModulesEdit,
	RecommendationsNew, RecommendationsEdit, RecommendationsIndex, 
	RecommendationsSummaryModulesNew, RecommendationsSummariesNewImport,
	RecommendationsNewImport, RecommendationsSummaryModulesNewImport,
	RecommendationsActionPlansEdit, RecommendationsActionPlansNew, 
	RecommendationsActionPlansNewImport,
  ReportsIndex, ReportsNew, ReportsEdit, ReportsSchedulesEdit, 
	ResearchCausesShow, ResearchCompaniesIndex, ResearchCompaniesShow, ResearchFundsIndex, 
  ResearchFundsShow,  ResearchCountriesShow, ResearchFavoritesIndex, 
  ResearchFundsTemperatureIndex, ResearchFundsRealWorldMetricsShow,
  ResearchCompaniesRealWorldMetricsShow, ResearchControversiesIndex, 
  ResearchFundsScreensIndex, ResearchScreensIndex, ResearchLocationsShow, 
  ResearchLocationsIndex, AssessmentsFaithBased, 
  SettingsAccountsMembershipsIndex, SettingsAccountsRestrictedAssetsIndex, 
  SettingsAccountsRestrictedAssetsEdit, SettingsAccountsMethodologiesCompaniesNew, 
  SettingsAccountsImagesIndex, SettingsUsersSecurity, SettingsAccountsEdit, 
  SettingsAccountsIntegrationsIndex, SettingsAccountsMethodologiesEdit, 
  SettingsAccountsMethodologiesImportsNew, SettingsAccountsCustomMetricsImportsNew, 
  SettingsAccountsCustomMetricsIndex, SettingsAccountsMethodologiesFormulasEdit, 
  SettingsAccountsMethodologiesFormulasNew, SettingsAccountsMethodologiesCausesEdit, 
  SettingsAccountsMethodologiesCausesNew, SettingsAccountsRolesNew, 
  SettingsAccountsRolesEdit
}


// Create a map for module-wide classes
const moduleClasses = {Admin, Auth, Help, Firms, Reports, Public}

// Set vars for listening for reloading page, maintaining scroll position if on same page
// https://stackoverflow.com/questions/39679154/how-to-persist-scrolls-with-turbolinks
const scrollPositions = {}
let page = $('body').data('page');
scrollPositions[page] = null

// Init page
const Init = function() {

  // Add lastpass and chrome ignore indicators
  if ($('body').attr('data-signed-in') === 'true') {
    $('input').attr('data-lpignore', 'true');
  }
  $('input').attr('autocomplete', 'off');

  // Show flash toast, if present on page
  if ($('.flash').length !== 0) { ToastFlash(); }

  // Show flash toast, if present on page
  if ($('body[data-color-mode=\'guide\']').length !== 0) { Guides(); }

  // Add border to navbar public, if on a public page
  if ($('.nav-public').length !== 0) { NavPublic(); }

  // Top navbar if signed in
  if ($('#navTop').length !== 0) {NavbarNotifications();}

  // If cards list
  if ($('.cards-list').length !== 0) {CardList();}

  // If collapsible cards list
  if ($('.card-list .collapse').length !== 0) {CollapsibleCardLists();}

  // Toggle user env
  if ($('#toggleDevMode').length !== 0) { ToggleUserEnv(); }

  // Add social share if present
  if ($('.social-share-button').length !== 0) {SocialShare();}

  // Collapse sidebar if present
  if ($('.minimize-sidebar').length !== 0) {Sidebar()};

  // Summernote default
  SummernoteDefault();

  // Add New badge to unvisited links
  if (typeof $('body').attr("data-unvisited-features") !== 'undefined') {
  	let unvisitedLinks = $('body').attr("data-unvisited-features");
  	if (unvisitedLinks.length !== 0) {
  		unvisitedLinks = JSON.parse(unvisitedLinks);
	  	let template = "<div class='bg-a-rating position-absolute badge' style='top: 4px; right: 8px; font-size: 12px;'>NEW</div>";
	  	let accountUrl = $('body').attr('data-account-url');
	  	$.each(unvisitedLinks, function(index,link) {

	  		// First look for links with account url
	  		let href = $('a[href=\'/' + accountUrl + link + '\']');
	  		if (href.length !== 0) { href.after(template); }

	  		// Then look for links without account url
	  		href = $('a[href=\'' + link + '\']');
	  		if (href.length !== 0) { href.after(template); }

	  	})
  	}
  }

  // DataTables error handling
  if ($.fn.dataTable !== undefined) {
		$.fn.dataTable.ext.errMode = function ( settings, helpPage, message ) { 
			console.log(message);
		};
  }

  // Toggle focus for typeahead on click of search button
  // Used in multiple nav bars
  if ($('.focus-search-form').length !== 0) {
    $('.focus-search-form').click(function() { FocusSearchForm($(this)); });
  }


  // Render default table if displayed and not already rendered
  if ( $('.default-table-on-load').length !== 0 ) {
    $('.default-table-on-load').each(function() {
	    let tableId = $(this).attr('id');
	    let wrapperId = tableId + '_wrapper';
	    if ( $('#' + wrapperId).length === 0 ) {
		    let table = new DefaultTable($('#' + tableId));
		    $.when( table.render() ).then( status => table.setUp(tableId) )
		  }
    })
  }


  // Toggle full-window view for cards
  if ($('.toggle-full-view').length !== 0) {
    $('.toggle-full-view').click(function() {
      let $card = $(this).closest('.card')
      let $chart = $card.find('.chart').data('c3-chart');
      $card.toggleClass('full-view');
      if ( typeof $chart !== 'undefined' ) {
        setTimeout(function() {
          $chart.flush();
        }, 100);
      }
    });
  }

  // Master search causes, companies, funds
  if ( $('#master_search_typeahead').length !== 0 ) {
    let masterSearch = new MasterSearchTypeahead('master_search_typeahead');
    $.when( masterSearch.render() );
  } else if ( $('#master_search_typeahead_clients').length !== 0 ) {
    let masterSearchClients = new MasterSearchClientsTypeahead('master_search_typeahead_clients');
    $.when( masterSearchClients.render() );
  } else if ( $('#master_search_typeahead_vantage').length !== 0 ) {
    let masterSearchVantage = new MasterSearchTypeaheadVantage('master_search_typeahead_vantage');
    $.when( masterSearchVantage.render() );
  }

  // Reposition images if repositionable image present
  if ($('.repositionable').length !== 0) {
    ImageReposition();
  }

  // Scroll to anchor on click
  if ($('.scroll-to-anchor').length !== 0) {
    $('.scroll-to-anchor').click(function(e) {
      e.preventDefault();
      let tag = $(this).attr('data-tag');
      return ScrollToTag(tag);
    });
  }

  // Set collapsible
  if ($('.collapse-item').length !== 0) {
    Collapsible();
  }

  // Init tooltips
  TooltipInit();

  // Remove toasts once they're hidden, so they don't show up on page change
  $('.toast').on('hidden.bs.toast', function () {
    $(this).remove();
  });

  // Auto-show modals
  if ($('.modal.auto-show').length !== 0) {

    // Show modal if present on page (if user signed in)
    $('.modal.auto-show').on('shown.bs.modal', function() {

      // get the top of the section
      let p = $(this).find('.modal-body').attr("data-scroll-p");

      // Scroll to section if p present
      if (typeof p !== 'undefined') { $(this).find('.modal-body').scrollTop(p); }

    }).modal({backdrop: 'static', keyboard: false});

  }

  // Set page, parent and should scroll attrs from body
  let page = $('body').data('page');
  let module = $('body').data('controller-module');
  let shouldScroll = $('body').data('should-scroll');

  // Load at scroll position, if body indicates we should
  if ( (typeof shouldScroll !== 'undefined') && (scrollPositions[page]) ) {
    window.scrollTo(0, scrollPositions[page]);
  }

	// Execute page class, if exists
	if (typeof pageClasses[page] !== 'undefined') {
		new pageClasses[page]().render();
	}

  // Execute module class, if exists
  if (typeof moduleClasses[module] !== 'undefined') {
    new moduleClasses[module]().render();
  }

}


// Call init function on page load
// Remove typeaheads, charts and datatables before cache
export default $(document).on("turbolinks:load", function() { 

	Init(); 

}).on("turbolinks:before-visit", function() {

  // Add listeners for reloading page, maintaining scroll position if on same page
  // https://stackoverflow.com/questions/39679154/how-to-persist-scrolls-with-turbolinks
  let page = $('body').data('page');
  scrollPositions[page] = window.scrollY;

}).on("turbolinks:before-cache", function() {

  // Collapse master search if present
  if ($('#master_search_typeahead').length !== 0) {
    $('#navbarSearchSm').removeClass('show');
    $('#master_search_typeahead').val('');
  }

  // Destroy typeaheads to avoid dup rendering on back button
  $('.typeahead').typeahead('destroy');

  // Empty legends from below charts
  if ($('.legend').length !== 0) { $('.legend').empty(); }

  // Remove google maps from BOM (window object)
  if (window.google !== undefined && window.google.maps !== undefined) {
    window.google.maps = undefined;
  }

  // Destroy datatables
  $('.dataTable.server-side tbody').remove(); // Avoid flickering/jumping on back button for tables that get data from server
  $('.dataTable').DataTable().destroy();

  // Remove modal backdrop; if don't remove before cache it appears twice on back button
  $('.modal-backdrop').remove();

  // Hide modals
  $('.modal').modal('hide');

  // Hide tooltips if not on mobile/touch
  let isTouchDevice = function() { 
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
  }
  if (isTouchDevice() === false) {
    $('[data-toggle="tooltip"]').tooltip('hide');
  }


});