import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'summernote/dist/summernote-bs4.min.js'

export default class ComplianceClientConditionsShow {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Search
    filterTypeahead('#search_portfolios', 'portfolios', '/' + accountUrl + '/portfolios/search?', 182, 'generic');
    filterTypeahead('#search_clients', 'clients', '/' + accountUrl + '/clients/search?', 384, 'generic');

    // On selecting a portfolio
    $('#search_portfolios').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).closest('.form-group').find('.hidden-field').val(suggestion.value);
    });

    // On selecting a client
    $('#search_clients').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
      $(this).closest('.form-group').find('.hidden-field').val(suggestion.value);
    });

    // Scroll if options passed
    if ($('.modal-body').attr("data-sl") === "true") {
      let height = $('.modal-body').height() + 200;
      $('.modal-body').scrollTop(height)
    }


  }
}