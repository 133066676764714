import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';

export default class ComplianceIndex {

  constructor() {}

  render() {

    // Set account url
    const accountUrl = $('body').attr('data-account-url');

    // Expand/minimize profile details
    ExpandContent();

    // Approval tests table
    $('#testsList').on('shown.bs.collapse', function (e) {
      if (($('#approval_tests_table').length > 0) && ($('#approval_tests_table tbody td').length === 0)) {
        let table = new DefaultTable($('#approval_tests_table'));
        $.when( table.render() ).then( status => table.setUp('approval_tests_table') )
      }
    })

    // Approvals table
    $('#approvalsList').on('shown.bs.collapse', function (e) {
      if (($('#approvals_table').length > 0) && ($('#approvals_table tbody td').length === 0)) {
        let table = new DefaultTable($('#approvals_table'));
        $.when( table.render() ).then( status => table.setUp('approvals_table') )

        // Search users
        filterTypeahead('#users_filter', 'users', '/' + accountUrl + '/users/search?', 95, 'generic');
        $('#users_filter').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
          $(this).blur();
          let oTable = $('#approvals_table').DataTable();
          oTable.search(suggestion.email).draw();
        });

      }
    });

    // Approval test runs table
    $('#testRunsList').on('shown.bs.collapse', function (e) {
      if (($('#approval_test_runs_table').length > 0) && ($('#approval_test_runs_table tbody td').length === 0)) {
        let table = new DefaultTable($('#approval_test_runs_table'));
        $.when( table.render() ).then( status => table.setUp('approval_test_runs_table') )
      }
    })

    // Approval rules table
    $('#approvalRulesList').on('shown.bs.collapse', function (e) {
      if (($('#approval_rules_table').length > 0) && ($('#approval_rules_table tbody td').length === 0)) {
        let table = new DefaultTable($('#approval_rules_table'));
        $.when( table.render() ).then( status => table.setUp('approval_rules_table') )
      }
    })

  }

}