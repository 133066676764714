export default class ApiWebhooksSubscriptionsEdit {

  constructor() {}

  render() {

    $('.webhook-model').change(function() {
      if (this.checked) { 
        $(this).closest('.input-group').find('.hidden-destroy').val(false);
      } else {
        $(this).closest('.input-group').find('.hidden-destroy').val(true);
      }
    })

  }

}