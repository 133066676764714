import DefaultTable from 'src/javascripts/components/tables/DefaultTable';


export default class ComplianceClientConditionsIndex {

  constructor() {}

  render() {

   	// Expand when click on table of contents item
    $('a.scroll-to-section').click(function(e) {
      e.preventDefault();
      let sectionId = $(this).attr('data-tag');
      setTimeout(function() {
        let aTag = $('#' + sectionId);
        $('html,body').animate({ scrollTop: aTag.offset().top - 180}, 'slow');
      }, 500);
    });

    // Flags table
    $('#clientConditionFlags').on('shown.bs.collapse', function (e) {
      if (($('#client_condition_flags_table').length > 0) && ($('#client_condition_flags_table tbody td').length === 0)) {
        let table = new DefaultTable($('#client_condition_flags_table'));
        $.when( table.render() ).then( status => table.setUp('client_condition_flags_table') )
      }
    });

    // Side letters table
    $('#clientSideLetters').on('shown.bs.collapse', function (e) {
      if (($('#client_side_letters_table').length > 0) && ($('#client_side_letters_table tbody td').length === 0)) {
        let table = new DefaultTable($('#client_side_letters_table'));
        $.when( table.render() ).then( status => table.setUp('client_side_letters_table') )
      }
    });

    // Conditions table
    $('#clientConditions').on('shown.bs.collapse', function (e) {
      if (($('#client_conditions_table').length > 0) && ($('#client_conditions_table tbody td').length === 0)) {
        let table = new DefaultTable($('#client_conditions_table'));
        $.when( table.render() ).then( status => table.setUp('client_conditions_table') )
      }
    });


  }
}
 