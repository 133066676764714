import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {genericDisplayTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import ManualListTypeaheadMultiple from 'src/javascripts/components/typeaheads/ManualListTypeaheadMultiple';

export default function() {

  // Set accounturl
  let accountUrl = $('body').attr('data-account-url');

  // Function to load relevant previous responses (e.g., same framework)
  let loadPrevResponses = function() {
    let questionnaireId = $('#responses_search').attr('data-questionnaire-id');
    let companyId = $('#questionnaire_response_company_id').val();
    let responseSearchUrl = '/' + accountUrl + '/questionnaires/responses/search?';
    if (typeof questionnaireId !== 'undefined') {responseSearchUrl += 'questionnaire_id=' + questionnaireId + '&';}
    if (typeof companyId !== 'undefined') {responseSearchUrl += 'company_id=' + companyId + '&';}
    $("#responses_search").typeahead("destroy");
    filterTypeahead('#responses_search', 'responses', responseSearchUrl, 282, 'generic');
  }

  // Questionnaires and companies search
  filterTypeahead('#questionnaires_search', 'questionnaires', '/' + accountUrl + '/questionnaires/search?', 82, 'generic');
  filterTypeahead('#users_search', 'users', '/' + accountUrl + '/users/search?', 382, 'generic');
  filterTypeahead('.approvers-search', 'approvers', '/' + accountUrl + '/users/search?', 382, 'generic');
  filterTypeahead('#companies_search', 'companies', '/' + accountUrl + '/companies/search?', 382, 'logo');

  // Check recent responses on select
  let checkRecentResponses = function() {
    let url = '/' + accountUrl + '/questionnaires/responses/check_recent_responses.js?';
    let params = {};
    let qid = $('#questionnaire_response_questionnaire_id').val();
    let pid = $('#questionnaire_response_company_id').val();
    if ((qid.length !== 0) && (pid.length !== 0)) {
      params['questionnaire_id'] = qid;
      params['company_id'] = pid;
      url = url + $.param(params);
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + $.param(params)
      });
    }
  }

  // Manual list typeaheads - multiple selection allowed
  if ($('.manual-list-typeahead-multiple').length !== 0) {
    $('.manual-list-typeahead-multiple').each(function(el){
      let id = $(this).attr('id');
      let t = new ManualListTypeaheadMultiple(el);
      t.render(id)
    });
  }

  // On selecting a questionnaire
  $('#questionnaires_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let qId = suggestion.value;
    $('#questionnaire_response_questionnaire_id').val(qId);
    let ps = $('#questionnaire_response_company_id').val();
    if (ps.length !== 0) {
      $('.submit-enabled').removeClass('d-none');
      $('.submit-disabled').addClass('d-none');
    } else {
      $('.submit-enabled').addClass('d-none');
      $('.submit-disabled').removeClass('d-none');
    }
    checkRecentResponses();
  })

  // On selecting a company
  $('#companies_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $('#questionnaire_response_company_id').val(suggestion.value);
    let qs = $('#questionnaire_response_questionnaire_id').val();
    loadPrevResponses();
    if (qs.length !== 0) {
      $('.submit-enabled').removeClass('d-none');
      $('.submit-disabled').addClass('d-none');
    } else {
      $('.submit-enabled').addClass('d-none');
      $('.submit-disabled').removeClass('d-none');
    }
    checkRecentResponses();
  })
  $('#companies_search').bind('typeahead:change', function(ev, selected) {
    console.log(selected);
    if (selected === "") {
      $('#questionnaire_response_company_id').val("");
      $('.submit-enabled').addClass('d-none');
      $('.submit-disabled').removeClass('d-none');
     }
  })

  // On selecting a user
  $('#users_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let qId = suggestion.value;
    $('#questionnaire_response_user_id').val(qId);
  })
  $('#users_search').bind('typeahead:change', function(ev, selected) {
    if (selected === "") {$(this).closest('.form-group').find('input[type=\'hidden\']').val("");}
  })

  // On selecting an approver
  $('.approvers-search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let qId = suggestion.value;
    $(this).closest('.form-group').find('input[type=\'hidden\']').val(qId);
  })
  $('.approvers-search').bind('typeahead:change', function(ev, selected) {
    if (selected === "") {$(this).closest('.form-group').find('input[type=\'hidden\']').val("");}
  })

  // On selecting a response
  $('#responses_search').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    let rId = suggestion.value;
    $('.hidden-pre-response-id').val(rId);
  })

  // Load previous response typeahead immediately if already selected (e.g., if editing an existing response)
  if ($('#prePopOption').val() === 'previous_response') {
    loadPrevResponses();
  }

  // Hide/unhide previous response fields
  $('#prePopOption').change(function() {
    let val = $(this).val();
    if (val === 'previous_response') {
      $('#selectPrevResponse').removeClass('d-none');
      loadPrevResponses();
    } else {
      $('#selectPrevResponse').addClass('d-none');
      $('.hidden-pre-response-id').val('');
      $('#responses_search').typeahead('val', '');
    }
  });

  // Default approval deadline based on selected submission deadline
  $('#questionnaire_response_submission_deadline').change(function() {
    let approvalDeadline = $('#questionnaire_response_approval_deadline');
    let daysAllowed = parseInt(approvalDeadline.attr('data-approval-days-allowed'));
    let date = new Date( $(this).val() );
    let yyyy = date.getFullYear().toString();
    let mm = (date.getMonth()+1).toString();
    let dd  = (date.getDate() + daysAllowed + 1).toString();
    approvalDeadline.val(yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]));
  })

  // Do not allow editing text
  // $('.typeahead').keydown(function(e) {
  //   e.preventDefault();
  //   return false;    
  // })

  // On selecting a fund as benchmark
  $('.clear-typeahead').click(function() {
    let t = $(this).closest('.form-group').find('.typeahead');
    t.typeahead('val', '');
    t.focus();
  })
  $('.clear-portfolio-company, .clear-questionnaire').click(function() {
    $('.submit-enabled').addClass('d-none');
    $('.submit-disabled').removeClass('d-none');
  })


}