import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class PortfoliosModelsNew {

  constructor() {}

  render() {

    // Set account url from body
    const accountUrl = $('body').attr('data-account-url');

    // Format display numbers in form
    NumberDisplay();

    $('.value-of-account').number( true, 0 );
    $('.value-of-account').on("change paste keyup", function() {

      // skip for arrow keys
      if(event.which >= 37 && event.which <= 40) return;

      // Notify user if difficult weight/count combination
      let numericVal = parseFloat( $(this).val() );

      if (Number.isNaN(numericVal)) {
        numericVal = 1;
      } else if (numericVal < 1) {
        numericVal = 100000;
        ToastCustom('Too small', 'Value must be greater than 0', 3000);
      } else if (numericVal >= 1000000000000) {
        numericVal = 100000;
        ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
      }

      // Set formatted string to return
      $('.value-of-account').val('$' + numericVal.toString());

      // Update hidden field
      $('#portfolio_model_value_of_account').val(numericVal);

    });

    filterTypeahead('.benchmark-filter', 'benchmark', '/' + accountUrl + '/funds/search_share_classes?require_holdings=true', 144, 'logo');

    // On update benchmark filter
    $('.benchmark-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      $('#reusableModelBenchmarkInput').val(suggestion.fund_id);
    });

    // On selecting a fund as benchmark
    $('.clear-typeahead').click(function() {
      let t = $(this).closest('.form-group').find('.typeahead');
      t.typeahead('val', '');
      t.focus();
    })



  }

}