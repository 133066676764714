import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import Swal from 'sweetalert2';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class DefaultTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = BuildApiUrl(tableId);

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set height if in full-window mode
    let $card = this.el.closest('.card');
    let $cardBody = this.el.closest('.card-body');
    let $height = $card.attr('data-scroll-height');
    if (typeof $height === 'undefined') {$height = $('#' + tableId).attr('data-scroll-height')}
    if (typeof $height === 'undefined') {
      if ($card.hasClass('full-view')) {
        $height = $card.height() - 180;
      } else {
        $height = $cardBody.height();
      }
    }

    // Page length
    let pageLength = this.el.attr('data-page-length');
    if (typeof pageLength === 'undefined') {pageLength = 50}

    // Sort order
    let orderCol = this.el.attr("data-order-col");
    if (typeof orderCol === 'undefined') {orderCol = 0;}
    let orderDir = this.el.attr('data-order-dir');
    if (typeof orderDir === 'undefined') {orderDir = 'asc'};

    // Set fixed columns
    let leftFixed = this.el.attr("data-left-fixed");
    if (typeof leftFixed === 'undefined') {leftFixed = 1;}

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: dataUrl,
      scrollY: $height,
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {leftColumns: leftFixed, rightColumns: 1},
      paging: true,
      pageLength: pageLength,
      searching: true,
      order: [[orderCol, orderDir]],
      dom: 'tlp',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ records",
        infoEmpty: "Nothing found. Reach out to us at support@ethosesg.com if you would like any help.",
        emptyTable: "Nothing found. Reach out to us at support@ethosesg.com if you would like any help.",
        zeroRecords: "Nothing found. Reach out to us at support@ethosesg.com if you would like any help."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).closest('.modal').find('.spinner-container').remove();
        $('#' + tableId).closest('.card-body').css('opacity', 1); 

        // Confirm and delete an item on click
        $('.delete-record').click(function(e) {

          // Set model and item ids
          let updatedTableId = $(this).closest('.table').attr('id');
          let recordId = $(this).closest('tr').attr('data-record-id');
          let deleteUrl = $(this).closest('.table').attr('data-delete-url');
          deleteUrl = deleteUrl.replace(':record_id', recordId);

          // Validate
          return Swal.fire({
            title: "Are you sure?",
            text: "Confirm that you would like to remove this record",
            animation: false,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light border',
              popup: 'animated fadeIn faster'
            }
          }).then((result) => {
            
            if (result.value) {

              // Unlink the account
              $.ajax({
                type: 'DELETE',
                url: deleteUrl,
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                success(result) { 
                  $('#' + updatedTableId).DataTable().ajax.reload();
                },
                error(error) { 
                  $('#' + updatedTableId).DataTable().ajax.reload();
                  ToastCustom('There was a problem', "There was a problem removing this recommendation, please contact us for support", 5000);
                }
              });

            }

          });

        });

        // Merge clients
        $('.merge-client').click(function(e) {
          let clientId = $(this).closest('tr').attr('data-record-id');
          let name = $(this).closest('tr').attr('data-record-name');
          $('#clientMergeModal').modal('show');
          $('#clientMergeModal').find('#delete_id').val(clientId);
          $('#clientMergeModal').find('#clientToDelete').val(name);
        });

      }, initComplete: function( settings ) {

        // Update table on move to or from full height
        $('.toggle-full-view').click(function() {
          let $card = $(this).closest('.card');
          Spinner($card);
          $card.find('.card-body').css('opacity', 0.1);
          setTimeout(function() {
            let $table = $('#' + tableId).DataTable();
            $table.clear().destroy();
            $table = new DefaultTable($('#' + tableId));
            $.when( $table.render() ).then( status => $table.setUp(tableId) )
          }, 200);
        });

      }

    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Search table
    let timer;
    $('#search_' + tableId).keyup(function(){
      let val = $(this).val();
      clearTimeout(timer);
      timer = setTimeout(function() { oTable.search(val).draw() }, 300);
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }


}
