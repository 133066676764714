import Spinner from 'src/javascripts/components/utilities/Spinner';
import PdfPoll from 'src/javascripts/components/reports/PdfPoll';
import PptPoll from 'src/javascripts/components/reports/PptPoll';
import CopyUrl from 'src/javascripts/components/reports/CopyUrl';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import StickySidebar from 'src/javascripts/components/nav/StickySidebar';
import SdgContributionPieChart from 'src/javascripts/components/charts/SdgContributionPieChart';
import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';
import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';
import PortfolioItemsUnderlyingTable from 'src/javascripts/components/tables/PortfolioItemsUnderlyingTable';
import ImpactDistPortfolio from 'src/javascripts/components/charts/ImpactDistPortfolio';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';
import ReturnsTable from 'src/javascripts/components/tables/ReturnsTable';
import CarbonComparison from 'src/javascripts/components/charts/CarbonComparison';
import CarbonFootprint from 'src/javascripts/components/charts/CarbonFootprint';
import SecurityWeightsTable from 'src/javascripts/components/tables/SecurityWeightsTable';
import PortfolioStocksPoll from 'src/javascripts/components/polling/PortfolioStocksPoll';
import PortfolioScreensPoll from 'src/javascripts/components/polling/PortfolioScreensPoll';
import PortfolioPerformancePoll from 'src/javascripts/components/polling/PortfolioPerformancePoll';
import DefaultTable from 'src/javascripts/components/tables/DefaultTable';
import SdgsTable from 'src/javascripts/components/tables/SdgsTable';
import ReturnsTableStatic from 'src/javascripts/components/tables/ReturnsTableStatic';
import RatingBellCurveChart from 'src/javascripts/components/charts/RatingBellCurveChart';


export default class Reports {

  constructor() {}

  render() {
    const accountUrl = $('body').attr('data-account-url');

    // Start PDF or PPT poll if generating
    PdfPoll();
    PptPoll();

    // Copy URL on click
    CopyUrl();

    // Expand/minimize profile details
    ExpandContent();

    // Expand problem statement on click
    ExpandExcerpt();

    // Sticky sidebar scrolling
    StickySidebar();

    // ACA Insights on expand
    $('#acaInsightsList').on('shown.bs.collapse', function() {
      Spinner('#acaInsightsList').closest(".card-list");
      $('#acaInsightsList').css('opacity', 0.25);
      let dataUrl = $('#acaInsightsList').attr('data-url');
      let params = {};
      let token = $('#acaInsightsList').attr('data-report-token');
      if (typeof token !== "undefined") {params['token'] = token}
      return $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js?' + $.param(params)
      });
    });

    // Advocacy on expand
    $('#advocacyList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingCard = cardList.find('.card-body');
      if (existingCard.length === 0) {
        Spinner(cardList);
        let reportId = $('#advocacyList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/advocacy.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#advocacyContainer').empty();
        })
      }
    });

    // All metrics on shown
    $('#allMetricsList').on('shown.bs.collapse', function() {

      // Metric scores distribution
      if ($('#impact_dist_metric_scores').length !== 0) {
        if (typeof $('#impact_dist_metric_scores').data('c3-chart') === 'undefined') {
          let chart = new ImpactDistMetricScores( $('#impact_dist_metric_scores') );
          $.when( chart.getData() )
           .then( data => chart.render(data) );
        }
      }

      // Metric scores table, only once click on data tab for first time
      if ($('#fund_metric_scores_table').length !== 0) {
        let tableId = 'fund_metric_scores_table';
        let metricScoresTable = new DefaultTable($('#' + tableId));
        $.when( metricScoresTable.render(tableId) ).then( status => metricScoresTable.setUp(tableId) );
      }

      // Metric scores table, only once click on data tab for first time
      if ($('#company_metric_scores_table').length !== 0) {
        let tableId = 'company_metric_scores_table';
        let metricScoresTable = new DefaultTable($('#' + tableId));
        $.when( metricScoresTable.render(tableId) ).then( status => metricScoresTable.setUp(tableId) );
      }

      // Portfolio metric scores, if present on page
      if ($('#portfolio_metric_scores_table').length !== 0) {
        let tableId = 'portfolio_metric_scores_table';
        let portfolioMetricScoresTable = new DefaultTable($('#' + tableId));
        $.when( portfolioMetricScoresTable.render(tableId) ).then( status => portfolioMetricScoresTable.setUp(tableId) );
      }

    });

    // Allocation on shown
    $('#allocationList').on('shown.bs.collapse', function() {
      let portfolioId = $('#sectorsTab').attr('data-portfolio-id');
      let token = $('#portfolioCharacteristicsCharts').attr('data-token');
      let params = {};
      params['scope'] = 'sectors';
      if (typeof token !== 'undefined') {params['token'] = token}
      let url = '/' + accountUrl + '/portfolios/' + portfolioId + '/characteristics.js?' + $.param(params);
      if ((typeof portfolioId !== 'undefined') && ($('.characteristic-row-comparison').length === 0) ) {
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
      }

      // On show each tab
      $('#portfolioCharacteristicsCharts a[data-toggle=\'tab\']').off().on('shown.bs.tab', function (e) {
        Spinner($('#charTabContent'));
        let portfolioId = $('#sectorsTab').attr('data-portfolio-id');
        let scope = $(this).attr('data-scope');
        let token = $('#portfolioCharacteristicsCharts').attr('data-token');
        let params = {};
        params['scope'] = scope;
        if (typeof token !== 'undefined') {params['token'] = token}
        let url = '/' + accountUrl + '/portfolios/' + portfolioId + '/characteristics.js?' + $.param(params);
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });      
      });
    });

    // Custom methodology on shown
    $('#customMethodologyRatingsList').on('shown.bs.collapse', function (e) {
      if ( typeof $('.custom-ratings-explorer') !== 'undefined' ) {
        let portfolioId = $('#customMethodologyRatingsList').attr('data-portfolio-id');
        let companyId = $('#customMethodologyRatingsList').attr('data-company-id');
        let params = {};
        let url;
        Spinner($('#customMethodologyRatingsList'));
        if (typeof portfolioId !== 'undefined') {
          url = "/" + accountUrl + "/portfolios/" + portfolioId + "/ratings/custom_methodology_scorecard";
        } else if (typeof companyId !== 'undefined') {
          url = "/companies/" + companyId + "/ratings/custom_methodology_scorecard";
        }
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });
      }
    })


    // Custom table on shown
    $('#customMethodologyTableList').on('shown.bs.collapse', function (e) {
      if ( typeof $('.questionnaire-scoring-table') !== 'undefined' ) {
        let portfolioId = $('#customMethodologyTableList').attr('data-portfolio-id');
        let companyId = $('#customMethodologyTableList').attr('data-company-id');
        let params = {};
        let url;
        Spinner($('#customMethodologyTableList'));
        if (typeof portfolioId !== 'undefined') {
          url = "/" + accountUrl + "/portfolios/" + portfolioId + "/ratings/custom_methodology_table";
        } else if (typeof companyId !== 'undefined') {
          url = "/companies/" + companyId + "/ratings/custom_methodology_table";
        }
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + $.param(params)
        });
      }
    })

    // Assets on expand
    $('#assetsList').on('shown.bs.collapse', function() {

      // Portfolio items proposed vs current, if present on page
      if ($('.portfolio-items-table-proposed').length > 0) {
        $('.portfolio-items-table-proposed').each(function() {
          let tableId = $(this).attr('id');
          let portfolioItemsTableProposed = new DefaultTable($('#' + tableId));
          $.when( portfolioItemsTableProposed.render(tableId) ).then( status => portfolioItemsTableProposed.setUp(tableId) );
        })

      } else if ($('#fundWeightTabs').length > 0) {

        if ($("#held_companies_table_wrapper").length === 0) {
          let tableId = 'held_companies_table';
          const table = new SecurityWeightsTable($('#' + tableId));
          $.when(table.render(tableId)).then( status => table.setUp(tableId) );
        }

        if ($("#held_funds_table_wrapper").length === 0) {
          let tableId = 'held_funds_table';
          const table = new SecurityWeightsTable($('#' + tableId));
          $.when(table.render(tableId)).then( status => table.setUp(tableId) );
        }

        if ($("#held_governments_table_wrapper").length === 0) {
          let tableId = 'held_governments_table';
          const table = new SecurityWeightsTable($('#' + tableId));
          $.when(table.render(tableId)).then( status => table.setUp(tableId) );
        }
      }
    });

    // Benchmarking on expand
    $('#benchmarkingList').on('shown.bs.collapse', function() {
      let container = $(this).find('.benchmarking-container');
      if (container.length > 0) {
        Spinner(container);
        let dataUrl = container.attr('data-url');
        let filters = {};
        let moduleId = container.attr('data-module-id');
        let companyId = container.attr('data-company-id');
        let questionnaireId = container.attr('data-questionnaire-id');
        let token = container.attr('data-token');
        if (typeof companyId !== 'undefined') {filters['company_id'] = companyId;}
        if (typeof questionnaireId !== 'undefined') {filters['questionnaire_id'] = questionnaireId;}
        if (typeof moduleId !== 'undefined') {filters["module_id"] = moduleId;}
        if (typeof token !== 'undefined') {filters['token'] = token;}
        $.ajax({
          type: "GET",
          dataType: "script",
          url: dataUrl + '.js?' + $.param(filters)
        });
      }
    });

    // Carbon comparison chart
    $('#carbonComparisonList').on('shown.bs.collapse', function() {
      CarbonComparison(); 
    })

    // Carbon footprint
    $('#carbonFootprintList').on('shown.bs.collapse', function() {
      CarbonFootprint(); 
    })

    // Carbon underground chart if not already loaded
    $('#cu200List').on('shown.bs.collapse', function() {
      let url = $('#carbon_underground_chart').attr('data-url');
      let backPath = $('#carbon_underground_chart').attr('data-back-path');
      let token = $('#carbon_underground_chart').attr('data-token');
      backPath = backPath + '?tab=riskTab';
      let params = {};
      params['back_path'] = backPath;
      params['token'] = token;
      Spinner($('.carbon-underground'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '?' + $.param(params)
      });
    });

    // Causes comparison on expand
    $('#causesComparisonList').on('shown.bs.collapse', function() {
      let el = $('.assessment-vis-proposed');
      Spinner(el);
      let reportId = el.attr('data-report-id');
      let portfolioId = el.attr('data-portfolio-id');
      let formulaId = el.attr('data-formula-id');
      let fundId = el.attr('data-fund-id');
      let companyId = el.attr('data-company-id');
      let benchmarkType = el.attr('data-benchmark-type');
      let benchmarkId = el.attr('data-benchmark-id');
      let backPath = el.attr('data-back-path');
      let url;
      if (typeof fundId !== 'undefined') {
        url = '/' + accountUrl + '/funds/' + fundId + '/ratings/assessment_proposed';
      } else if (typeof companyId !== 'undefined') {
        url = '/' + accountUrl + '/companies/' + companyId + '/ratings/assessment_proposed';
      } else {
        url = '/' + accountUrl + '/portfolios/causes/assessment_vis_proposed';          
      }
      let params = {};
      params['account_url'] = accountUrl;
      params['portfolio_id'] = portfolioId;
      params['report_id'] = reportId;
      params['formula_id'] = formulaId;
      params['benchmark_type'] = benchmarkType;
      params['benchmark_id'] = benchmarkId;
      params['back_path'] = backPath;
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });
      $(document).on("turbolinks:before-cache", function() { el.empty(); })
    });

    // Company scorecards on expand
    $('#companyScorecardList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let card = cardList.find('.card');
      if (card.length === 0) {
        Spinner(cardList);
        let reportId = $('#companyScorecardList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/company_scorecards.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#companyScorecardsContainer').empty();
        })
      }
    });

    // Controversies table on expand
    $('#controversiesList').on('shown.bs.collapse', function (e) {

      // Get data for container
      let existingChart = $(this).find('.controversies-progress');
      if (existingChart.length === 0) {
        Spinner($('#controversiesContainer'));
        let reportId = $('#controversiesList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/controversies.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#controversiesContainer').empty();
        })
      }

    });

    // Controversies detail table on expand
    $('#controversyDetailsList').on('shown.bs.collapse', function (e) {

      // Start table processing
      ResearchFiltering();

    });

    // ESG topics on expand
    $('#esgTopicsList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingTopics = cardList.find('.portfolio-screen-card');
      if (existingTopics.length === 0) {
        Spinner(cardList);
        let reportId = $('#esgTopicsList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/esg_topics.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#esgTopicsContainer').empty();
        })
      }
    });

    // Financials on shown
    $('#assetFinancialList').on('shown.bs.collapse', function (e) {

      // Poll for performance if needed
      PortfolioPerformancePoll();

      // Load data if no generating message
      if ($('.generating-performance-message').length === 0) {

        // Initialize a new chart instance for each chart on the page
        if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

          // Set chartId and chart object
          // Get data, render chart, set up chart, and create connected table, if exists
          let chart = new ReturnsDollarsChart($('#returns_chart'));
          $.when(chart.getData())
           .then( data => chart.render(data))
           .then( chartObj => chart.setUp(chartObj));

        } else if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

          // Set chartId and chart object
          // Get data, render chart, set up chart, and create connected table, if exists
          let chart = new ReturnsChart($('#returns_chart'));
          $.when(chart.getData())
           .then( data => chart.render(data))
           .then( chartObj => chart.setUp(chartObj));

        }

        // Toggle returns chart between hypothetical growth and percentage returns
        ToggleReturnsChart();

        // Portfolio items return attrs, if present on page
        if ($('#returns_table').length > 0) {
          let returnsTable = new ReturnsTable($('#returns_table'));
          $.when( returnsTable.render('returns_table') ).then( status => returnsTable.setUp('returns_table') );
        }

      }

    });

    // Financials impact assessment on shown
    $('#financialImpactAssessmentList').on('shown.bs.collapse', function() {

      // Initialize a new chart instance for each chart on the page
      if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsDollarsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      } else if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

        // Set chartId and chart object
        // Get data, render chart, set up chart, and create connected table, if exists
        let chart = new ReturnsChart($('#returns_chart'));
        $.when(chart.getData())
         .then( data => chart.render(data))
         .then( chartObj => chart.setUp(chartObj));

      }

      // Toggle returns chart between hypothetical growth and percentage returns
      ToggleReturnsChart();

      // Portfolio items return attrs, if present on page
      if ($('#returns_table_static').length > 0) {
        let table = new ReturnsTableStatic($('#returns_table_static'));
        $.when( table.render('returns_table_static') ).then( status => table.setUp('returns_table_static') );
      }

    });

    // Impact stories on expand
    $('#impactStoriesList').on('shown.bs.collapse', function() {
      Spinner($('#portfolioFundImpactStoriesGraphic'));
      let portfolioId = $('#portfolioFundImpactStoriesGraphic').attr('data-portfolio-id');
      let fundId = $('#portfolioFundImpactStoriesGraphic').attr('data-fund-id');
      let token = $('#portfolioFundImpactStoriesGraphic').attr('data-report-token');
      if (typeof fundId !== 'undefined') {
        $.ajax({
          type: "GET",
          dataType: "script",
          url: '/' + accountUrl + '/funds/' + fundId + '/stories.js?token=' + token
        });
      } else {
        $.ajax({
          type: "GET",
          dataType: "script",
          url: '/' + accountUrl + '/portfolios/' + portfolioId + '/fund_stories.js?token=' + token
        });        
      }
    });

    // My causes on expand
    $('#myCausesList').on('shown.bs.collapse', function() {
      let el = $('.assessment-vis');
      Spinner(el);

      // Set params for loading metrics
      let portfolioId = el.attr('data-portfolio-id');
      let reportId = el.attr('data-report-id');
      let fundId = el.attr('data-fund-id');
      let companyId = el.attr('data-company-id');
      let formulaId = el.attr('data-formula-id');
      let backPath = el.attr('data-back-path');
      let url;
      if (typeof fundId !== 'undefined') {
        url = '/' + accountUrl + '/funds/' + fundId + '/ratings/assessment';
      } else if (typeof companyId !== 'undefined') {
        url = '/' + accountUrl + '/companies/' + companyId + '/ratings/assessment';
      } else {
        url = '/' + accountUrl + '/portfolios/causes/assessment_vis';          
      }
      let params = {};
      params['report_id'] = reportId;
      params['portfolio_id'] = portfolioId;
      params['formula_id'] = formulaId;
      params['back_path'] = backPath;
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });
      $(document).on("turbolinks:before-cache", function() { el.empty(); })

    });

    // Overall impact on expand
    $('#overallImpactList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingChart = $(this).find('.impact-scale');
      if (existingChart.length === 0) {
        Spinner(cardList);
        let formulaId = $('#overallImpactChartContainer').attr('data-formula-id');
        let reportId = $('#overallImpactChartContainer').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/overall_impact';
        let params = {};
        params['formula_id'] = formulaId;
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js?' + $.param(params)
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#overallImpactChartContainer').empty();
        })
      }
    });

    // Overall risk on expand
    $('#overallRiskList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingChart = $(this).find('.impact-scale');
      if (existingChart.length === 0) {
        Spinner(cardList);
        let formulaId = $('#overallRiskChartContainer').attr('data-formula-id');
        let reportId = $('#overallRiskChartContainer').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/overall_risk';
        let params = {};
        params['formula_id'] = formulaId;
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js?' + $.param(params)
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#overallRiskChartContainer').empty();
        })
      }
    });

    // Policies on expand
    $('#policiesList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingPolicies = cardList.find('.policy-row');
      if (existingPolicies.length === 0) {
        Spinner(cardList);
        let reportId = $('#policiesList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/policies.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#policiesContainer').empty();
        })
      }
    });

    // Portfolio stocks and screens polling
    PortfolioStocksPoll();
    PortfolioScreensPoll();

    // Questionnaire modules on expand
    $('#questionnaireModulesList').on('shown.bs.collapse', function (e) {
      let container = $(this).find('.gauge-container');
      let gauges = $(this).find('#questionnaireGauges');
      let loaded = gauges.find('a');
      if ((container.length > 0) && (loaded.length === 0)) {
        Spinner(container);
        let dataUrl = container.attr('data-url');
        let filters = {};
        let token = container.attr('data-token');
        if (typeof token !== 'undefined') {filters['token'] = token;}
        $.ajax({
          type: "GET",
          dataType: "script",
          url: dataUrl + '.js?' + $.param(filters)
        });
      }
    })

    // Risk categories on expand
    $('#riskCategoriesList').on('shown.bs.collapse', function() {
      let companyId = $('#riskCategoriesContainer').attr('data-company-id');
      let fundId = $('#riskCategoriesContainer').attr('data-fund-id');
      let portfolioId = $('#riskCategoriesContainer').attr('data-portfolio-id');
      let token = $('#riskCategoriesContainer').attr('data-token');
      let url;
      if (typeof companyId !== 'undefined') {
        url = "/" + accountUrl + "/companies/" + companyId + "/metric_scores/risk_categories";
      } else if (typeof fundId !== 'undefined') {
        url = "/" + accountUrl + "/funds/" + fundId + "/metric_scores/risk_categories";
      } else {
        url = "/" + accountUrl + "/portfolios/" + portfolioId + "/metric_scores/risk_categories";
      }
      let params = {};
      params['token'] = token;
      Spinner($('#riskCategoriesContainer'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });
      $(document).on("turbolinks:before-cache", function() {
        $('#riskCategoriesContainer').empty();
      })
    });

    // Risk drivers on expand
    $('#riskDriversList').on('shown.bs.collapse', function() {
      let companyId = $('#riskDriversGraphic').attr('data-company-id');
      let fundId = $('#riskDriversGraphic').attr('data-fund-id');
      let portfolioId = $('#riskDriversGraphic').attr('data-portfolio-id');
      let token = $('#riskDriversGraphic').attr('data-token');
      let url;
      if (typeof companyId !== 'undefined') {
        url = "/" + accountUrl + "/companies/" + companyId + "/metric_scores/risk_drivers";
      } else if (typeof fundId !== 'undefined') {
        url = "/" + accountUrl + "/funds/" + fundId + "/metric_scores/risk_drivers";
      } else {
        url = "/" + accountUrl + "/portfolios/" + portfolioId + "/metric_scores/risk_drivers";
      }
      let params = {};
      params['token'] = token;
      Spinner($('#riskDriversGraphic'));
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });
      $(document).on("turbolinks:before-cache", function() {
        $('#riskDriversGraphic').empty();
      })
    });

    // Screens on expand
    $('#screensList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingPolicies = cardList.find('.screens-info');
      if (existingPolicies.length === 0) {
        Spinner(cardList);
        let reportId = $('#screensList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/screens.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#screensContainer').empty();
        })
      }
    });

    // SDGs on expand
    $('#sdgsList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingCard = cardList.find('.card');
      if (existingCard.length === 0) {
        Spinner(cardList);
        let reportId = $('#sdgsList').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/sdgs.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#sdgsContainer').empty();
        })
      }
    });

    // SDGs contribution on expand
    $('#sdgsContributionList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingCard = cardList.find('.sdg-contribution-row');
      if (existingCard.length === 0) {
        Spinner(cardList);
        let recordType = $('.sdgs-contribution-chart').attr('data-record-type');
        let fundId = $('.sdgs-contribution-chart').attr('data-fund-id');
        let portfolioId = $('.sdgs-contribution-chart').attr('data-portfolio-id');
        let token = $('.sdgs-contribution-chart').attr('data-token');
        let backPath = $('.sdgs-contribution-chart').attr('data-back-path');
        let params = {};
        let url;
        if (recordType === 'Fund') {
          url = '/' + accountUrl + '/funds/' + fundId + '/sdg_contribution';
        } else {
          url = '/' + accountUrl + '/portfolios/causes/sdg_contribution';
          params['portfolio_id'] = portfolioId;          
        }
        params['token'] = token;
        params['back_path'] = backPath;
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js?' + $.param(params)
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#sdgsContributionContainer').empty();
        })
      }
    });

    // SDG pie and bar charts
    $('[href="#sdgContBarTab"]').on('shown.bs.tab', function (e) {
      $('.sdg-contributors-legend .adjustable').removeClass('lightest').addClass('opaque');
      $('.sdg-contributors-legend .adjustable-text').removeClass('text-gray400');
    });
    $('[href="#sdgContPieTab"]').on('shown.bs.tab', function (e) {
      $('.sdg-contributors-legend .adjustable').removeClass('opaque').addClass('lightest');
      $('.sdg-contributors-legend .adjustable-text').addClass('text-gray400');

      // Render chart if present
      if ( ($('#sdg_contribution_pie_chart').length !== 0) && (typeof $('#sdg_contribution_pie_chart').data('c3-chart') === 'undefined') ) {
        Spinner($('#sdgContPieTab'));
        let chart = new SdgContributionPieChart( $('#sdg_contribution_pie_chart') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }
    });

    // SDGs cont dollar tab
    $('[href="#sdgContDollarTab"]').on('shown.bs.tab', function (e) {
      $('.sdg-contributors-legend .adjustable').removeClass('opaque').addClass('lightest');
      $('.sdg-contributors-legend .adjustable-text').addClass('text-gray400');
      if ( ($('#sdgsContributionRevenue').length > 0) && ($('#sdgsContributionRevenue .sdg-revenue-row').length === 0) ) {
        Spinner($('#sdgsContributionRevenue'));
        let portfolioId = $('#sdgsContributionRevenue').attr('data-portfolio-id');
        let url = '/' + accountUrl + '/portfolios/causes/sdg_contribution_revenue';
        let params = {};
        params['portfolio_id'] = portfolioId;
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js?' + $.param(params)
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#sdgsContributionRevenue').empty();
        })
      } 
    });

    // Typical portfolio
    $('#typicalPortfolioList').on('shown.bs.collapse', function() {
      Spinner( $('.assessment-vis-default') );
      let el = $('.assessment-vis-default');
      let assessmentId = el.attr('data-assessment-id');
      let backPath = el.attr('data-back-path');
      let url = '/' + accountUrl + '/portfolios/causes/assessment_vis_default/' + assessmentId;
      let params = {};
      params['back_path'] = backPath
      $.ajax({
        type: "GET",
        dataType: "script",
        url: url + '.js?' + $.param(params)
      });
      $(document).on("turbolinks:before-cache", function() {
        $('.assessment-vis-default').empty();
      })
    })

    // Underlying items on shown
    $('#underlyingStocksList').on('shown.bs.collapse', function() {
      if ($('#portfolio_items_underlying_table').length > 0) {
        let tableId = 'portfolio_items_underlying_table';
        let portfolioItemsUnderlyingTable = new PortfolioItemsUnderlyingTable($('#' + tableId));
        $.when( portfolioItemsUnderlyingTable.render(tableId) ).then( status => portfolioItemsUnderlyingTable.setUp(tableId) );
      } else {
        let tableId = 'held_companies_table';
        const table = new SecurityWeightsTable($('#' + tableId));
        $.when(table.render(tableId)).then( status => table.setUp(tableId) );
      }
    });

    // Vantage dashboard on expand
    $('#vantageDashboardList').on('shown.bs.collapse', function() {
      let cardList = $(this).closest('.card-list');
      let existingChart = $(this).find('.dashboard-card');
      if (existingChart.length === 0) {
        Spinner(cardList);
        let reportId = $('#vantageDashboardContainer').attr('data-report-id');
        let url = '/' + accountUrl + '/reports/' + reportId + '/vantage_dashboard.js';
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url
        });
        $(document).on("turbolinks:before-cache", function() {
          $('#vantageDashboardContainer').empty();
        })
      }
    });


    // Expand default sections if present
    $('#myCausesList').collapse('show');
    $('#causesComparisonList').collapse('show');
    $('#vantageDashboardList').collapse('show');

    // Auto open if option passed
    $('.collapse[data-auto-open=\'true\']').collapse('show');

  }

}